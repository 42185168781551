// Grid system
// .main {
//   @include make-sm-column($main-sm-columns);
//   .sidebar-primary & {
//     @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
//   }
// }
// .sidebar {
//   @include make-sm-column($sidebar-sm-columns);
// }

// .main {
//   @media screen and (min-width: 768px) {
//     width: 63.6666666667% !important;
//   }
// }

// .sidebar {
//   @media screen and (min-width: 768px) {
//     width: 36.3333333333% !important;
//   }
// }

.main-full, .main-part, .sidebar {
  position: relative;
}

.main-full {
  @media screen and (min-width: 768px) {
    width: 100%;
    float: left;
  }
  .page-header:last-child {
    display: none;
  }
}

.main-part {
  @media screen and (min-width: 768px) {
    float: left;
    width: 62.6666666667%;
  }
}

.sidebar {
  @media screen and (min-width: 768px) {
    float: left;
    width: 37.3333333333%;
  }
}

.container {
  @media screen and (min-width: 1200px) {
    width: 1050px;
  }
}

/*********************
FLEX BOX
*********************/

.displayFlex {
  @media screen and (min-width: 768px) {
    display: flex;
  }
}

.displayFlex992 {
  @media screen and (min-width: 992px) {
    display: flex;
  }
}

.alignCenterFlex {
  -webkit-align-items: center; /* Safari */
  align-items:         center;
}

.flex-container {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  -webkit-align-items: stretch; /* Safari */
  .flex-column {
    flex-shrink: 0;
  }
}