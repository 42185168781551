// Buttons
.btn, .wpcf7-submit {
  text-shadow: none;
  border-radius: 4px;
  background-image: none;
  padding: 10px 22px;
  border: none;
  letter-spacing: .1em;
  font-weight: 400;
  text-transform: uppercase;
}

.btn-smaller {
  padding: 5px 10px;
  font-weight: 300;
  font-size: 92%;
}

.btn-bigger {
  padding: 12px 16px;
  font-weight: 300;
  font-size: 1.4em;
}

.btn-white {
  background-color: $white;
  color: $red !important;
  &:hover {
    color: darken($red, 15%);
  }
}

.btn-red {
  background-color: $red;
  color: $white !important;
  &:hover {
    background-color: darken($red, 15%);
    color: $white;
  }
}

.btn-black {
  background-color: $black;
  color: $white !important;
  &:hover {
    color: $white;
    background-color: $darkgray;
  }
}

.btn-blue {
  background-color: $blue;
  color: $white !important;
  &:hover {
    background-color: darken($blue, 20%);
    color: $white;
  }
}

.btn-orange {
  background-color: #ef6000;
  color: $white !important;
  &:hover {
    background-color: darken(#ef6000, 20%);
    color: $white;
  }
}

.btn-dark-gray {
  background-color: $darkgray;
  color: $white !important;
  &:hover {
    background-color: darken($darkgray, 10%);
    color: $white;
  }
}

.btn-white-outline {
  background-color: $black;
  color: $white !important;
  &:hover {
    background-color: #242424;
    color: $white;
  }
  border: 1px solid $white;
}

.btn-yellow {
  background-color: $yellow;
  color: $white !important;
  &:hover {
    background-color: darken($yellow, 15%);
    color: $white;
  }
}