.sidebar {
  @media screen and (max-width: 767px) {
    @include fixedmobilewidth;
  }
  section:first-child {
    h3 {
      margin-top: 0;
    }
    div {
      h3 {
        margin-top: inherit;
      }
    }
  }
  .viewall {
    display: none;
    color: $white;
    &:hover {
      color: $white;
    }
  }
}

.widget {
  margin: 0 0 2.5em 0;
  h3 {
    text-transform: uppercase;
    margin-bottom: 1em;
    // - alt color for dark background
    color: $white;
  }
  ul, ol {
    margin: 0 0 1.5em 0;
    padding: 0;
    list-style: none;
    li {
      list-style: none;
    }
  }
  ul.misc-media-slim {
    li {
      background: $white;
      margin-bottom: .75em;
      div.color-background {
        background-color: rgba(0,0,0,0.25);
        @include transitions;
        &:hover {
          background-color: rgba(0,0,0,0.8);
        }
        h3, h4 {
          padding: .25em 0;
          @include type-oswald-700;
        }
        h4 {
          @include type-oswald-400;
          padding-bottom: .4em;
        }
        a, a:visited {
          color: $white;
          &:hover {
            color: $white;
          }
        }
      }
    }
  }
  .textwidget {
    background-color: $white;
    padding: 1em;
  }
  .tour-date-entry {
    font-size: .8em !important;
    .tour-date {
      font-size: 1em !important;
    }
    .tour-tix {
      @media screen and (min-width: 768px) {
        text-align: left !important;
      }
    }
    .tour-content {
      border-top: 1px dashed $bordercolor;
      margin-top: .75em;
      padding-top: .75em;
    }
    .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-12 {
      padding-left: 8px !important;
      padding-right: 7px !important;
    }
  }
}

.widget_wpt {
  .entry-title a {
    font-weight: 400 !important;
  }
  img {
    @include paddingBorder;
  }
  .wpt_thumb_small {
    height: 69px;
  }
}

.wpt_widget_content .tab_title a {
  font-weight: 300;
  text-transform: uppercase;
}

.side-nav {
  .nav-next, .nav-previous {
    height: 70px;
    margin-bottom: 1em;
    overflow: hidden;
    .fixed-width {
      width: 70px; /* The size of the fixed block. */
      float: left;
      img {
        width: 70px;
        max-width: 100%;
        height: auto;
      }
      a:first-child {
        display: block;
        width: 70px;
        height: 70px;
        position: absolute;
        z-index: 100;
      }
      a:last-child {
        position: relative !important;
      }
    }
    .fluid {
      height: 70px;
      @include floatleft100;
      margin-left: -70px; /* The size of the fixed block. */
      overflow: hidden;
      .inner-block {
        height: 70px;
        display: table;
        margin-left: 70px; /* The size of the fixed block. */
        line-height: 24px;
        span {
          line-height: 70px;
          @include inlinefloatleft;
          padding: 0 .5em;
          font-size: 2em;
          font-weight: 300;
        }
        h4 {
          display: table-cell;
          margin: 0;
          height: 70px;
          vertical-align: middle;
          line-height: 1em;
          padding: 0 8px 0 0;
          text-transform: uppercase;
          a {
            font-size: (14 / 16) * 1em;
            @media screen and (min-width: 992px) {
              font-size: (13 / 16) * 1em;
            }
            font-weight: 300;
            color: $black;
            &:hover {
              color: $red;
            }
          }
        }
      }
    }
  }
  li:last-child {
    margin: 0;
  }
}

.album-info {
  h2 {
    margin: .5em 0;
  }
  .btn {
    margin: 0 4px .75em 0;
  }
}

.tour-link, .tour-link:visited {
  color: $white;
  &:hover {
    color: $red;
  }
}

