// Feature - see swiper.scss for more

.home-feature {
  padding: 0;
  @media screen and (max-width: 991px) {
    margin-top: 58px;
  }
  h2.h1 {
    @media screen and (max-width: 767px) {
      padding-top: .75em;
      font-size: 2em;
    }
  }
  .home-feature-wrapper {
    padding: 0 0 2em 0;
    background: rgba(255,255,255,0.8);
    @media screen and (min-width: 768px) {
      padding: 2em;
    }
    @media screen and (max-width: 991px) {
      background-color: $white;
    }
    .excerpt p {
      font-size: 1em;
    }
  }
  .home-feature-image {
    img {
      width: 100%;
      height: auto;
      background-color: $white;
      @media screen and (min-width: 768px) {
        @include paddingBorder;
      }
    }
    iframe {
      background-color: $white;
      @media screen and (min-width: 768px) {
        @include paddingBorder;
      }
    }
  }
  .home-feature-text-wrapper {
    @media screen and (max-width: 767px) {
      padding: 0 1em;
      .excerpt {
        display: none;
      }
    }
    h2 {
      margin: .5em 0 .25em 0;
      @media screen and (max-width: 767px) {
        font-size: 1.5em;
        line-height: 1.3;
      }
      @media screen and (min-width: 768px) {
        margin: 0 0 0.5em 0;
      }
      a {
        color: $black;
        &:hover {
          color: $gray;
        }
      }
    }
  }
}


// splash

body.page-splash {
  background-color: $black;
}

.max-width-1325 {
  max-width: 1325px;
  margin-left: auto;
  margin-right: auto;
}

.splash-spacer {
  @media screen and (max-width: 767px) {
    background: url(../images/tour-splash-small.jpg) top center no-repeat;
    background-size: 100% auto;
  }
  @media screen and (min-width: 768px) {
    background: url(../images/tour-splash-wide.jpg) top center no-repeat;
    background-size: 100% auto;
  }
  font-size: 1.4em;

  h4 {
    margin: 1.5em 0 .75em 0;
    border-bottom: 2px solid $white;
    padding: 0 0 .25em 0;
    display: inline-block;
    clear: both;
  }
}

.splash-spacer-desktop {
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
}

.splash-spacer-mobile {
  display: block;
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.red-splash {
  color: #dd0307;
}

hr.white {
  height: 2px;
  display: block;
  background-color: $white;
  color: $white;
}

.tour-splash {
  .tour-date-entry {
    &:nth-child(even) {
      background: none !important;
    }
    .btn {
      margin-top: 2px;
      margin-bottom: 2px;
    }
    .btn-smaller {
      font-size: 90%;
      font-weight: 400 !important;
    }
    border-bottom: 1px solid $darkgray;
    .tour-date, .tour-venue, .tour-location {
      color: $white;
      font-size: 1.15em;
    }
  }
  @media screen and (max-width:767px) {
    font-size: 1.15em;
  }
}

.splash-socials a {
  display: inline-block;
  margin: 0 .5em;
}


