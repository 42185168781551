

/******************************************************************
Site Name: Avenged Sevenfold
Author: Mike Lythcott
******************************************************************/

/*********************
GENERAL STYLES
*********************/

body {
  @include type-oswald-300;
  line-height: 1.5;
  font-size: 62.5%;
  color: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222;
}

body#tinymce {
  font-family: inherit;
  background-color: $white;
  background-image: none;
  color: $black;
}

body,html {
  height: 100%;
}

body.wp-admin {
  font-size: 100% !important;
}

// // SKIN //
// @media screen and (min-width: 992px) {
//   body {
//
//     background-attachment: fixed;
//   }

//   body.home {
//
//     background-attachment: fixed;
//     background-size: cover;
//   }
// }

p {
  line-height: 1.6em;
  margin-bottom: 1.5em;
}

.width100 {
  width: 100%;
}

.widthHeight100 {
  width: 100%;
  height: 100%;
}

.widthAuto {
  width: auto !important;
}

.height100 {
  height: 100%;
}

.noBorder {
  border: 0;
}

.clearthis {
  clear:both;
  display: block;
  height: 1px;
  line-height: 1px;
}

.clearBoth {
  clear: both;
}

.noFloat {
  float: none !important;
}

.noMargin {
  margin: 0 !important;
}

.marginBottom {
  margin-bottom: 2em;
}

.marginBottom1 {
  margin-bottom: 1em;
}

.noMarginBottom {
  margin-bottom: 0;
}

.noMarginTop {
  margin-top: 0;
}

.marginTop {
  margin-top: 2em;
}

.marginTop1 {
  margin-top: 1em;
}

.marginTopBottom {
  margin: 2em 0;
}

.marginTopBottom1 {
  margin: 1em 0;
}

.noPadding {
  padding: 0;
}

.padding {
  padding: 2em;
}

.padding1 {
  padding: 1em;
}

.paddingHalf {
  padding: 0.5em;
}

.paddingTop {
  padding-top: 2em;
}

.paddingTop1 {
  padding-top: 1em;
}

.paddingTopHalf {
  padding-top: .5em;
}

.paddingBottom {
  padding-bottom: 2em;
}

.paddingBottom1 {
  padding-bottom: 1em;
}

.paddingTopBottom {
  padding: 2em 0;
}

.paddingTopBottom1 {
  padding: 1em 0;
}

.paddingTopBottom3 {
  padding: 3em 0;
}

.paddingLeftRight1 {
  padding-left: 1em;
  padding-right: 1em;
}

.uppercase {
  text-transform: uppercase;
}

.displayBlock {
  display: block !important;
}

.displayNone {
  display: none !important;
}

.displayInlineBlock {
  display: inline-block !important;
}

.overflowHidden {
  overflow: hidden;
}

.fontWeight300 {
  font-weight: 300 !important;
}

.noBoxShadow {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.box-shadow {
  box-shadow: 0px 0px 10px #d5d5d5;
  -webkit-box-shadow: 0px 0px 10px #d5d5d5;
}

.text-shadow {
  text-shadow: 0px 0px 10px rgba(0,0,0,0.95);
  -webkit-text-shadow: 0px 0px 10px rgba(0,0,0,0.95);
}

/*********************
LAYOUT & GRID STYLES
*********************/

// see directly below, next one for homepage only. wrap and container are the same on the homepage
.wrap {
  @media screen and (min-width: 768px) and (max-width: 991px) {
    width: 100%;
  }
}

  // wrap alternative for homepage only
  body.home, body.blog {
    .container {
      @media screen and (min-width: 768px) and (max-width: 991px) {
        width: 100%;
      }
    }
  }

.wrap, body.post-type-archive-videos .feature {
  // thanks to fixed header in header.scss, 0 - 991px. Also, see directly below for homepage and video post type archive
  @media screen and (max-width: 991px) {
    margin-top: 47px;
  }
}

// .wrap for home & video post type archive only
body.home, body.blog, body.post-type-archive-videos {
  .wrap {
    @media screen and (min-width: 992px) {
      margin-top: 0;
    }
  }
}

body.single-post, body.home, body.blog {
  .wrap {
    padding-top: 2em;
    @media screen and (max-width: 991px) {
      padding-top: 1.5em;
    }
  }
}

body.single-post, body.page, body.post-type-archive {
  .wrap {
    @media screen and (max-width: 991px) {
      padding-top: 1em;
    }
  }
}

@media screen and (min-width: 992px) {
  .wrap {
    background-color: #161616;
  }
  body.home .wrap, body.blog .wrap {
    background-color: #161616;
  }
}

body.home, body.blog, body.single-post, body.post-type-archive-photos, body.post-type-archive-videos, body.category, body.single-videos, body.page-news {
  .content {
    @media screen and (min-width: 768px) {
      padding: 0 10px;
    }
  }
}

.main, .main-full, .main-part {
  padding: 0 !important;
}

/* 404 */
body.error404 {
  .page-header {
    text-align: center;
    h1 {
      text-transform: uppercase;
      font-size: 5em;
    }
  }
}

/* see sidebar.scss for sidebar settings */

/*********************
LINK STYLES
*********************/

a, a:visited {
  color: $link-color;
  @include transitions;
  text-decoration: none;

  &:hover, &:focus {
    color: $link-hover;
    text-decoration: none;
  }

  &:link {
    -webkit-tap-highlight-color : rgba( 0, 0, 0, 0.3 );
  }
}

.white-link {
  color: $white !important;
  &:hover {
    color: $red !important;
  }
}

/******************************************************************
H1, H2, H3, H4, H5 STYLES
******************************************************************/

// Headers
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1.2;
  text-rendering: optimizelegibility;
  font-weight: 400;
  font-family: $serif;
  a {
    text-decoration: none;
  }
}

h1, .h1 { font-size: (36 / 16) * 1em; }
h2, .h2 { font-size: (30 / 16) * 1em; }
h3, .h3 { font-size: (24 / 16) * 1em; }
h4, .h4 { font-size: (18 / 16) * 1em; }
h5, .h5 { font-size: (16 / 16) * 1em; }
h6, .h6 { font-size: (12 / 16) * 1em; }

/*********************
IMAGE
*********************/

img {
  display: block;
  height: auto;
  max-width: 100%;
  // see below for situation where max-width 100% makes image vanish, so have to disable this setting
}

img.media-object {
  max-width: inherit;
  width: 60px;
  height: auto;
  border: 1px solid $bordercolor;
  padding: 5px;
}

/*********************
VERTICAL RHYTHYM
*********************/

// see posts.scss for alt font sizes for .entry-content, since the fonts for this theme are small
header, .main, .main-full, .main-part, .sidebar, footer, .wrap, p, .storynav, .featured-image, .overlay, .btn, .sub-footer, .homepage-video, .section,
.page-header, .home-feature, .excerpt, .feature, .splash, .vertical-rhy {
  font-size: 16px;
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
  @media screen and (min-width: 992px) {
    font-size: 15px;
  }
  @media screen and (min-width: 1200px) {
    font-size: 16px;
  }
}

p {
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
}

.bigger-text {
  font-size: 1.2em;
}

/*********************
PAGE NAVIGATION
*********************/

.article-navigation {
  padding: 1.5em 0px;
  text-align: center;
  clear: both;
  ul.pagination {
    border: 10px solid $black;
    span.current {
      color: $black;
    }
    li {
      color: $black;
      span {
        border-color: $black !important;
      }
      a {
        color: $black;
        border-color: $black !important;
        &:hover {
          color: $link-hover !important;
          background: none !important;
        }
      }
    }
    li.active a {
      background-color: $black !important;
      color: $white;
    }
  }
}

/*********************
CONTENT
*********************/

.featured-image {
  img {
    width: 100%;
    height: auto;
  }
}
