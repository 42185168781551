// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 85%;
  }
  @media screen and (min-width: 992px) {
    width: 87%;
  }
  @media screen and (min-width: 1200px) {
    width: 89%;
  }
}
.search-form .search-field {
  @extend .form-control;
  height: 42px;
  line-height: 42px;
  width: 98%;
  font-size: 20px;
  font-weight: 300;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-red;
}

.input-group {
  background-color: $white;
  border-radius: 38px;
  padding-left: 1.15em;
  .form-control {
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
    font-size: 1em;
    text-transform: uppercase;
  }
}

.wpcf7-form {
  .wpcf7-text, .wpcf7-textarea {
    padding: .75em 1em;
    border-radius: 1.7em;
    border: 1px solid $light-gray;
  }
}