header.banner {
  padding: 0;
  z-index: 100;
  @media screen and (max-width: 991px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $black;
    padding: 1em 0;
    // see below for if logged in
    background: url("../images/header-back-mobile.jpg") repeat-x top;
    background-size: cover;
  }
  @media screen and (min-width: 992px) {
    .container {
      background-color: $black;
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }

  .brand {
    width: auto;
    display: block;
    h1 {
      text-indent: -9999px;
      height: 1px;
      overflow: hidden;
    }
    img.mobile-logo {
      width: auto;
      height: 32px;
    }
    img {
      width: auto;
      height: 88px;
    }
  }

  .navbar {
    @media screen and (max-width: 991px) {
      min-height: 20px;
    }
  }

  nav.navbar-default {
    background: none !important;
    border: none !important;
    margin: 0 !important;

    ul {
      // 992px + see below for less than that, or mobilenav.scss for mobile specific styles
      @media screen and (min-width: 992px) {
        li {
          a {
            display: block;
            height: 45px;
            line-height: 45px;
            padding: 0 16px;
            text-shadow: 0px 0px 10px rgba(0,0,0,0.95);
            -webkit-text-shadow: 0px 0px 10px rgba(0,0,0,0.95);
            @media screen and (min-width: 992px) and (max-width: 1199px) {
              padding: 0 14px;
            }
            font-size: 1.1em;
            color: $white;
            font-weight: 400;
            &:hover {
              color: $bordercolor;
            }
          }
          &:first-child {
            a {
              @media screen and (min-width: 992px) {
                padding-left: 0;
              }
            }
          }
          &:last-child {
            a {
              @media screen and (min-width: 992px) {
                padding-right: 0;
              }
            }
          }
        }
      }
      // 0 - 991px
      @media screen and (max-width: 991px) {
        li {
          a {}
        }
      }
      li.fanclub a {
        color: $yellow;
        font-weight: 400;
      }
    }

    ul#menu-main-menu {
      margin-top: 3px;
      li a {
        font-size: 1.4em;
      }
    }

    ul.social-nav {
      li a {
        padding: 0 1em;
      }
    }
    #menu-main-menu, #menu-secondary-nav {
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
  }

  .navbar-default .navbar-nav>.active>a {
    background: none;
    color: $white;
    font-weight: 400;
  }
}

// needed for when logged in, or nav is hidden
body.logged-in {
  header.banner {
    @media screen and (max-width: 782px) {
      top: 46px;
    }

    @media screen and (min-width: 783px) and (max-width: 991px) {
      top: 32px;
    }
  }
}