body.page-tour {
  .main-full {
    min-height: 900px;
  }
}

.tour-date-entry {
  border-bottom: 1px solid $bordercolor;
  font-size: .95em;
  @media screen and (min-width: 1024px) {
    font-size: 1.0em;
  }
  @media screen and (min-width: 1200px) {
    font-size: 1.1em;
  }
  @include type-oswald-300;
  &:last-child {
    border: 0;
  }
  .paddingHalf {
    @media screen and (max-width: 767px) {
      padding: .6em 0 0 0 !important;
    }
  }
  .tour-date {
    font-size: 1.1em;
    @include type-oswald-700;
    @media screen and (min-width: 1024px) {
      font-size: 1em;
    }
  }
  .tour-content {
    p {
      margin: 8px 0 0 0;
      @include type-oswald-300;
      display: inline-block;
      text-transform: capitalize;
      font-style: italic;
      display: inline;
      width: 95%;
    }
    .paddingHalf {
      border-top: 1px dashed $bordercolor;
      margin-top: .4em;
    }
  }
  .tour-location {
    @media screen and (max-width: 767px) {
      color: $gray;
    }
  }
  .btn {
    margin-bottom: 5px;
  }
  .btn, .tour-venue, .tour-location {
    @include type-oswald-300;
  }
  .tour-tix {
    @media screen and (min-width: 768px) {
      text-align: right;
    }
  }
  &:nth-child(even) {
    background-color: $lightestgray;
  }
}