/*********************
GLOBAL VARIABLES
*********************/

// Glyphicons font path
$icon-font-path:        "../fonts/";

// Fonts
$serif:        "Oswald", helvetica, arial, tahoma, sans-serif;
$sans-serif:   "Oswald", Cambria, Times New Roman, Times, serif;

@mixin type-oswald-300 {
  font-family: 'Oswald', sans-serif;
  font-weight: 300 !important;
}

@mixin type-oswald-400 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400 !important;
}

@mixin type-oswald-700 {
  font-family: 'Oswald', sans-serif;
  font-weight: 700 !important;
}

.type-oswald-300 {
  @include type-oswald-300;
}

.type-oswald-400 {
  @include type-oswald-400;
}

.type-oswald-700 {
  @include type-oswald-700;
}

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$darkgray:          #222;
$darkergray:        #353535;
$gray:              #444;
$medium-gray:       #777;
$medium-dark-gray:  #555;
$light-gray:        #B6BCBE;
$lightestgray:      #f9f9f9;
$bordercolor:       #e6e6e6;
$backgroundgray:    #f7f7f6;


$text-color:      #333;
$heading-color:   #000;
$white:           #fff;
$black:           #000;
$red:             #cc0000;
$yellow:          #f6cb03;
$blue:            #035cd5;

// Link Colors
$link-color:      $red;
$link-hover:      darken($link-color, 15%);


.black-background, .red-background, .grey-background, .dark-grey-background {
  background-color: $black;
  color: $white;
  a, a:visited {
    color: $white;
    &:hover {
      color: $white;
    }
  }
}

.black-background {
  a, a:visited {
    &:hover {
      color: $red;
    }
  }
}

.grey-background {
  background-color: $gray;
}

.dark-grey-background {
  background-color: $darkergray;
}

.red-background {
  background-color: $red;
}

.light-gray-background {
  background-color: $bordercolor;
}

.white-background {
  background-color: $white;
}

.white {
  color: $white;
}

.black {
  color: $black;
}

.red {
  color: $red !important;
}

.light-gray {
  color: $light-gray;
}

.gray {
  color: $gray;
}

.bordercolor {
  border: 1px solid $bordercolor !important;
}

.borderTop {
  border-top: 1px solid $bordercolor;
}

.borderBottom {
  border-bottom: 1px solid $bordercolor;
}

@mixin paddingBorder {
  padding: 5px;
  border: 1px solid $bordercolor;
}

.paddingBorder {
  @include paddingBorder;
}

// Global
$gutter: (30 / 16) * 1em;
$gutterpx: 30px;

// Media Queries
$screen-xxs-min: 0px;
$screen-xxs-max: 479px;
$screen-xs-min: 480px;
$screen-xs-max: 767px;
$screen-sm-min: 768px;
$screen-sm-max: 991px;
$screen-md-min: 992px;
$screen-md-max: 1199px;
$screen-lg-min: 1200px;

// Break points
$break-small: 30em; // 480px @ 16px
$break-small-medium: 37.5em; // 600px @ 16px
$break-medium: 48em; // 768px @ 16px
$break-large: 64em; // 1024px @ 16px
$break-xlarge: 75em; // 1200px @ 16px

/* Custom Bootstrap grid for 480-767px layout */
.col-ms-1, .col-ms-2, .col-ms-3, .col-ms-4,
.col-ms-5, .col-ms-6, .col-ms-7, .col-ms-8,
.col-ms-9, .col-ms-10, .col-ms-11, .col-ms-12 {position:relative; padding-left:15px; padding-right:15px;}
@media (min-width: 480px) and (max-width: 767px) {
  .container {max-width:748px;}
  .col-ms-1, .col-ms-2, .col-ms-3, .col-ms-4,
  .col-ms-5, .col-ms-6, .col-ms-7, .col-ms-8,
  .col-ms-9, .col-ms-10, .col-ms-11 {float:left;}
  .col-ms-1 {width:8.33333333%;}
  .col-ms-2 {width:16.66666667%;}
  .col-ms-3 {width:25%;}
  .col-ms-4 {width:33.33333333%;}
  .col-ms-5 {width:41.66666667%;}
  .col-ms-6 {width:50%;}
  .col-ms-7 {width:58.33333333%;}
  .col-ms-8 {width:66.66666667%;}
  .col-ms-9 {width:75%;}
  .col-ms-10 {width:83.33333333%;}
  .col-ms-11 {width:91.66666667%;}
  .col-ms-12 {width:100%;}
}

/*  Bootstrap Clearfix */

/*  Tablet  */
@media (min-width:767px){

  /* Column clear fix */
  .col-lg-1:nth-child(12n+1),
  .col-lg-2:nth-child(6n+1),
  .col-lg-3:nth-child(4n+1),
  .col-lg-4:nth-child(3n+1),
  .col-lg-6:nth-child(2n+1),
  .col-md-1:nth-child(12n+1),
  .col-md-2:nth-child(6n+1),
  .col-md-3:nth-child(4n+1),
  .col-md-4:nth-child(3n+1),
  .col-md-6:nth-child(2n+1){
    clear: none;
  }
  .col-sm-1:nth-child(12n+1),
  .col-sm-2:nth-child(6n+1),
  .col-sm-3:nth-child(4n+1),
  .col-sm-4:nth-child(3n+1),
  .col-sm-6:nth-child(2n+1){
    clear: left;
  }
}


/*  Medium Desktop  */
@media (min-width:992px){

  /* Column clear fix */
  .col-lg-1:nth-child(12n+1),
  .col-lg-2:nth-child(6n+1),
  .col-lg-3:nth-child(4n+1),
  .col-lg-4:nth-child(3n+1),
  .col-lg-6:nth-child(2n+1),
  .col-sm-1:nth-child(12n+1),
  .col-sm-2:nth-child(6n+1),
  .col-sm-3:nth-child(4n+1),
  .col-sm-4:nth-child(3n+1),
  .col-sm-6:nth-child(2n+1){
    clear: none;
  }
  .col-md-1:nth-child(12n+1),
  .col-md-2:nth-child(6n+1),
  .col-md-3:nth-child(4n+1),
  .col-md-4:nth-child(3n+1),
  .col-md-6:nth-child(2n+1){
    clear: left;
  }
}


/*  Large Desktop  */
@media (min-width:1200px){

  /* Column clear fix */
  .col-md-1:nth-child(12n+1),
  .col-md-2:nth-child(6n+1),
  .col-md-3:nth-child(4n+1),
  .col-md-4:nth-child(3n+1),
  .col-md-6:nth-child(2n+1),
  .col-sm-1:nth-child(12n+1),
  .col-sm-2:nth-child(6n+1),
  .col-sm-3:nth-child(4n+1),
  .col-sm-4:nth-child(3n+1),
  .col-sm-6:nth-child(2n+1){
    clear: none;
  }
  .col-lg-1:nth-child(12n+1),
  .col-lg-2:nth-child(6n+1),
  .col-lg-3:nth-child(4n+1),
  .col-lg-4:nth-child(3n+1),
  .col-lg-6:nth-child(2n+1){
    clear: left;
  }
}

// Table Render & Vertical Align
.render-table {
  display: table;
  .render-table-cell {
    display:table-cell;
    vertical-align:middle;
  }
}

// No Gutters
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.row.no-gutters .slim-wrap > [class^="col-"],
.row.no-gutters .slim-wrap > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

@mixin logo {
  display: block;
  width: 168px;
  height: 63px;
}

/*********************
SASS MIXINS
*********************/

@mixin linkhover {
  a, a:visited {
    color: $link-color;
    &:hover {
      color: $link-hover;
    }
  }
}

// EM calculations

$browser-context: 16; // Default

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

// usage
// h1 {
//   font-size: em(32);
// }
// result
// h1 {
//   font-size: 2em;
// }

// Retina Images

@mixin replace-text-with-dimensions-for-retina($image-standard, $image-retina) {
  @include replace-text-with-dimensions($image-standard);
  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (   -moz-min-device-pixel-ratio: 2),
  only screen and (     -o-min-device-pixel-ratio: 2),
  only screen and (        min-device-pixel-ratio: 2),
  only screen and (           min-resolution: 192dpi),
  only screen and (           min-resolution: 2dppx) {
      background-image: image-url($image-retina);
      background-size: 100% 100%;
  }
}

// Usage:
//#logo {
    //@include replace-text-with-dimensions-for-retina('image.png', 'image@2x.png');
//}

@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}

// Usage:
// div.logo {
//   background: url("logo.png") no-repeat;
//   @include image-2x("logo2x.png", 100px, 25px);
// }

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// Usage:
// .sixteen-nine {
//   @include aspect-ratio(16, 9);
// }

/*********************
POSITIONING
*********************/

/// Shorthand mixin for offset positioning
/// @param {String} $position - Either `relative`, `absolute` or `fixed`
/// @param {Length} $top [null] - Top offset
/// @param {Length} $right [null] - Right offset
/// @param {Length} $bottom [null] - Bottom offset
/// @param {Length} $left [null] - Left offset
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

//Usage
// .foo {
//   @include position(absolute, $top: 1em, $left: 50%);
// }

// compiles

// .foo {
//   position: absolute;
//   top: 1em;
//   left: 50%;
// }

@mixin posabsolutebottomleft {
  position: absolute;
  bottom: 0;
  left: 0;
}

@mixin centeredcss {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(-50%, -50%) !important;
}

.centeredcss {
  @include centeredcss;
}

.positionAbsoluteFull {
  @include position(absolute, $top: 0, $left: 0, $right: 0, $bottom: 0);
}

/*********************
TRANSITION
*********************/

/*
I totally rewrote this to be cleaner and easier to use.
You'll need to be using Sass 3.2+ for these to work.
Thanks to @anthonyshort for the inspiration on these.
USAGE: @include transition(all 0.2s ease-in-out);
*/

@mixin transition($transition...) {
  // defining prefixes so we can use them in mixins below
  $prefixes:      ("-webkit-", "" );
  @each $prefix in $prefixes {
    #{$prefix}transition: $transition;
  }
}

// Transitions

@mixin transition300ms {
  @include transition-property(all);
  @include transition-duration(300ms);
  @include transition-timing-function(ease-in-out);
}

@mixin transition500ms {
  @include transition-property(all);
  @include transition-duration(500ms);
  @include transition-timing-function(ease-in-out);
}

@mixin transition0ms {
  @include transition-property(all);
  @include transition-duration(0ms);
  @include transition-timing-function(ease-in-out);
}

@mixin transition-all {
    -webkit-transition: all 0.35s;
    -moz-transition: all 0.35s;
    transition: all 0.35s;
}

@mixin fadein {
  opacity: 1;
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
  -o-transition: opacity .25s ease-in-out;
  -khtml-transition: opacity .25s ease-in-out;
}

@mixin transitions {
  transition: all 0.3s ease-in-out !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -khtml-transition: all 0.3s ease-in-out;
}

/*********************
CLEARFIXIN'
*********************/

// Contain floats: nicolasgallagher.com/micro-clearfix-hack/
.clearfix {
  zoom: 1;
  &:before, &:after { content: ""; display: table; }
  &:after { clear: both; }
}

/*********************
TOOLS
*********************/

// BORDER-BOX ALL THE THINGS! (http://paulirish.com/2012/box-sizing-border-box-ftw/)
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing:    border-box;
  box-sizing:         border-box;
}

// http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
.image-replacement {
  text-indent: 1000%;
  white-space: nowrap;
  overflow: hidden;
}

@mixin imagereplacement {
  text-indent: 1000%;
  white-space: nowrap;
  overflow: hidden;
}

/*********************
BORDER RADIUS
*********************/

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

// Usage:
// .button {
//   @include border-radius(5px);
// }

/*********************
OPACITY
*********************/

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// Usage
// .article-heading {
//   @include opacity(0.8);
// }

/*********************
ELLIPSIS
*********************/

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Usage
// .text-truncate {
//   @include text-truncate;
// }

/*********************
FONT SIZE & LINE HEIGHT
*********************/

@mixin font-size($sizeValue: 12 ){
  font-size: $sizeValue + px; //fallback for old browsers
  font-size: (0.125 * $sizeValue) + rem;
}

// Usage
// body {
//   @include font-size(16);
// }

@mixin line-height($heightValue: 12 ){
    line-height: $heightValue + px; //fallback for old browsers
    line-height: (0.125 * $heightValue) + rem;
}

// Usage
// body {
//   @include line-height (16);
// }

/*********************
IMAGES
*********************/

@mixin borderedimage {
  padding: 1em;
  border: 1px solid $bordercolor;
}

@mixin maximage {
  max-width: 100%;
  width: 100%;
  height: auto;
}

/*********************
PULL OVERRIDES
*********************/

// Pull overrides
@media (max-width: $screen-xs-max) {
  .pull-xs-left {
    float: left;
  }
  .pull-xs-right {
    float: right;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .pull-sm-left {
    float: left;
  }
  .pull-sm-right {
    float: right;
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .pull-md-left {
    float: left;
  }
  .pull-md-right {
    float: right;
  }
}

@media (min-width: $screen-lg-min) {
  .pull-lg-left {
    float: left;
  }
  .pull-lg-right {
    float: right;
  }
}

/*********************
COMMON & MISC
*********************/

@mixin blockfloat {
  display: block;
  float: left;
}

@mixin autowidthinlineblock {
  width: auto;
  display: inline-block;
}

@mixin inlinefloatleft {
  display: inline-block;
  float: left;
}

@mixin floatleft100 {
  float: left;
  width: 100%;
}

@mixin width100heightautoboth {
  width: 100%;
  height: auto;
  clear: both;
}

@mixin width100heightauto {
  width: 100%;
  height: auto;
}

@mixin blockclear {
  display: block;
  clear: both;
}

@mixin inlinemiddle {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

@mixin fixedmobilewidth {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  float: none;
}

