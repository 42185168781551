.sub-footer {
  padding: 4em 0;
}

#footer {
  background-color: $black;
  color: $light-gray;
  padding: 2em 0;
  @media screen and (max-width: 767px) {
    text-align: center;
  }

  ul.footer-navigation {
    padding: 0;
    width: 100% !important;
    li {
      list-style: none;
      display: inline-block;
      float: none !important;
      a, a:visited {
        padding: 1.5em 1em;
        @media screen and (max-width: 767px) {
          padding: .75em 1em;
        }
        color: $white !important;
        font-size: .9em;
        &:hover {
          color: $red !important;
          background: none !important;
        }
      }
      a:first-child {
        padding-left: 0;
      }
    }
  }

  ul.legal-nav {
    @media screen and (max-width: 767px) {
      margin-top: 1.25em;
    }
  }

  ul.social-nav {
    li a {
      padding-top: 0;
      font-size: 1em;
    }
  }

  .footer-logo {
    width: 290px;
    height: auto;
    margin: 0 auto 1em auto;
  }

  .copyright, .textwidget {
    font-size: .9em;
  }
}