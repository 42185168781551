/* header.scss has hidden desktop nav for mobile size */

#op {
  display:none;
}

.lower{
  float: right;
  color: $white;
  display: none;
  @media screen and (max-width: 991px) {
    display: block;
  }
  &:hover {
  }
  label {
    text-align:center;
    margin: 0 !important;
    display: block;
    width: 33px;
    height: 33px;
    text-align: center;
    line-height: 33px;
    padding-top: 4px;
  }
}

.overlay{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.9);
  z-index: 999;
  label {
    width: 58px;
    height:58px;
    position: absolute;
    right: 20px;
    top: 20px;
    background: url("../images/cross.png");
    z-index: 1000;
    cursor:pointer;
  }
  nav {
    text-align: center;
    position: relative;
    top: 50%;
    height: 60%;
    font-size: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1001;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    height: 100%;
    position: relative;
    li {
      display: block;
      height: auto;
      width: 50%;
      float: left;
      height: calc(100% / 8);
      min-height: 46px;
      a {
        font-weight: 300;
        display: block;
        color: $white !important;
        text-decoration:none;
        -webkit-transition: color 0.2s;
        transition: color 0.2s;
        text-transform:uppercase;
        padding: 12px 0;
      }
      a:hover, a:focus {
        color: $red !important;
        background: none;
      }
    }
    li.social {
      width: 25%;
    }
    li.fanclub {
      a {
        color: $yellow !important;
        font-weight: 400;
      }
    }
  }
}

.lower~.overlay-hugeinc{
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.5s, visibility 0s 0.5s;
  transition: opacity 0.5s, visibility 0s 0.5s;
}

#op:checked~.overlay-hugeinc{
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

#op:checked~.overlay-hugeinc~.navbar~.banner {
  height: 100% !important;
}

.overlay-hugeinc nav {
  -moz-perspective: 300px;
  ul {
    opacity: 0.4;
    -webkit-transform: translateY(-25%) rotateX(35deg);
    transform: translateY(-25%) rotateX(35deg);
    -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
    transition: transform 0.5s, opacity 0.5s;
  }
}

#op:checked~.overlay-hugeinc nav ul {
  opacity: 1;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

#op:not(:checked)~.overlay-hugeinc nav ul {
  -webkit-transform: translateY(25%) rotateX(-35deg);
  transform: translateY(25%) rotateX(-35deg);
}