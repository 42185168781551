// vertical rhythym for entry content and excerpt
.entry-content, .excerpt {
  font-family: arial;
  // p, ol, ul {
  //   @media screen and (max-width: 767px) {
  //     font-size: 16px;
  //   }
  //   @media screen and (min-width: 768px) {
  //     font-size: 16px;
  //   }
  //   @media screen and (min-width: 992px) {
  //     font-size: 16px;
  //   }
  //   @media screen and (min-width: 1200px) {
  //     font-size: 18px;
  //   }
  // }
}

/* posts - home & archive */

.featured-image {
  background-color: $black;
}

body.home {
  @media screen and (max-width: 767px) {
    .hentry {
      @include fixedmobilewidth;
      h2.entry-title {
        font-size: 1.5em;
        padding: .6em;
      }
    }
  }
}

.excerpt p {
  margin: 0;
  font-size: .95em;
}

.news-blocks {
  overflow: hidden;
  z-index: 4;
  position: relative;
  margin-bottom: 2em;
  header {
    time {
      color: #aaa;
    }
  }
  @media screen and (max-width: 767px) {
    margin: 0 auto 2.5em auto;
    max-width: 400px;
    header {
      h2 {
        font-size: 1.6em;
        @include transitions;
        color: $white;
        &:hover {
          color: $red;
        }
      }
    }
  }
  .featured-image {
    @media screen and (max-width: 767px) {
      background-color: $black;
      padding: 5px;
      margin-bottom: 1em;
    }
  }
  time span {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .news-blocks {
    background-color: $black;
    @include transitions;
    &:hover {
      background-color: $black;
      .featured-image {
        @include opacity(1);
      }
      header {
        @include opacity(0);
      }
    }
    .featured-image {
      @include opacity(0.3);
      @include transitions;
      overflow: hidden;
      position: relative;
      border: 1px solid $white;
    }
    a.link-wrapper {
      @include position(absolute, $top: 0, $left: 0, $right: 0, $bottom: 0);
    }
    header {
      @include centeredcss;
      width: 90%;
      color: $white;
      h2 {
        text-shadow: 2px 2px 7px rgba(0,0,0,.85);
        margin-bottom: 5px;
        @include type-oswald-400;
      }
      a, a:visited {
        color: $white;
        &:hover {
          color: $white;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .news-blocks {
    header {
      h2 {
        font-size: 1.4em;
      }
    }
  }
}

.normal-post {
  header {
    h2, h4 {
      padding: 12px 15px;
    }
  }
  .excerpt {
    padding: 12px 15px;
  }
}

/* misc colors */

.wpt_widget_content .wpt-postmeta, .wpt_comment_content, .wpt_excerpt {
  color: $black !important;
}

// .wpt_widget_content {
//   .wpt_thumb_small {
//     a {
//       display: block;
//       background: url(http://avenged.site/app/themes/a7x/dist/images/default-thumb.jpg) no-repeat top;
//       background-size: 100%;
//       img {
//         @include opacity(0);
//       }
//     }
//   }
// }

/* single */

body.single {
  .featured-image {}
}

.tags{
    display: inline-block;

  a, a:visited {
    margin: 10px 5px 0 25px;
    padding:0 10px 0 12px;
    background: $gray;
    color: $white;
    text-decoration:none;
    border-bottom-right-radius:4px;
    border-top-right-radius:4px;
    display: inline-block;
    width: auto;
    height:24px;
    line-height:23px;
    position:relative;
    font-size: 12px;
    text-transform: uppercase;
    &:hover {
      background-color: $gray;
      color: $white;
    }
  }

  a:hover:before {
    border-color:transparent $gray transparent transparent;
    color: $white;
  }

  a:before {
    content:"";
    float:left;
    position:absolute;
    top:0;
    left:-12px;
    width:0;
    height:0;
    border-color:transparent $gray transparent transparent;
    border-style:solid;
    border-width:12px 12px 12px 0;
  }

  a:after {
    content:"";
    position:absolute;
    top:10px;
    left:0;
    float:left;
    width:4px;
    height:4px;
    border-radius:2px;
    background: $white;
  }
}

.addthis_native_toolbox {
  text-align: center;
  display: inline-block !important;
  margin-top: 1em;
}

.storynav {
  .nav-next, .nav-previous {
    width: 50%;
    float: left;
  }
  @media screen and (max-width: 767px) {
    .nav-next, .nav-previous {
      padding: 0.5em;
      width: 100%;
    }
  }
}

.related-posts, .facebook-comments {
  h2 {
    @include type-oswald-300;
  }
  .media-left {
    height: 80px;
    overflow: hidden;
    img.media-object {
      width: 80px;
    }
  }
}

#disqus_thread {
  padding: 1em;
}

/* Related Bootstrap overrides */

.media {
  border-top: 1px solid $bordercolor;
  padding-top: 15px;
  .media-body p {
    margin: 0;
  }
}

/* gallery */

.gallery {
  margin: 0 -1.1666667% 1.75em;
}

.gallery-item {
  display: inline-block;
  max-width: 33.33%;
  padding: 0 1.1400652% 2.2801304%;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-1 .gallery-item {
  max-width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-icon img {
  margin: 0 auto;
  border: 1px solid $black;
}

.gallery-caption {
  display: block;
  line-height: 1.6153846154;
  padding-top: 0.5384615385em;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none;
}

/* Music */

.albumwrap {
  .btn {
    margin: 5px 5px 5px 0;
  }

  ol.list-item {
    list-style: none;
    padding: 0;
    li.list-group-item {
      display: list-item;
      counter-increment: item;
    }
    li.list-group-item:before {
      content: counter(item);
      background-color: $black;
      border-radius: 30px;
      color: $white;
      display: inline-block;
      margin-right: .5em;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
  }
}

.embed p {
  margin: 0;
  line-height: 1px;
}

/* Photos */

body.post-type-archive-photos {
  .hentry {
    header {
      @include centeredcss;
      z-index: 10;
      width: 96%;
      h2 {
        font-size: 1.35em;
      }
      a, a:visited {
        background-color: $black;
        background: rgba(0,0,0,0.85);
        padding: .75em 1em;
        display: block;
        color: $white;
        &:hover {
          color: $red;
        }
      }
    }
  }
}

/* Video & Photo - alt color for dark background */

body.post-type-archive-videos, body.post-type-archive-photos {
  .featured-image {
    @include paddingBorder;
    background-color: $black;
    border: none;
  }
  .entry-title {
    color: $white;
  }
  .featured-image.white-background {
    background-color: $white !important;
  }
}

//* misc *//

.wpp-list {
  background-color: $white;
  li {
    border-bottom: 1px solid $bordercolor;
    padding: 10px;
    img {
      width: 60px;
      margin-right: 10px;
      border: 1px solid $bordercolor;
      padding: 5px;
    }
    &:last-child {
      border: 0;
    }
    a, a:visited {
      color: $black;
      &:hover {
        color: $red;
      }
    }
  }
}

#swipebox-slider .slide img {
  @media screen and (min-width: 768px) {
    max-height: 90%;
  }
}
